@import "../variables";

.detail {
    &--text-blur {
        color: transparent;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }

    &--disabled {
        span {
            color: rgba($color-danger, 0.4) !important;
        }

        i {
            color: rgba($color-danger, 0.4) !important;
        }

        svg {
            color: rgba($color-danger, 0.4) !important;
        }
    }

    &--unlock {
        cursor: pointer;
        //background-color: #f3f3f3;
        //text-align: right;
        //border: 1px solid #bebebe;
        font-weight: bold;
        padding-left: 20px;
        background-image: url("../layout/unlock_data.svg");
        //background-image: repeating-linear-gradient(-45deg,
        //        #cdcdcd 10px 30px,
        //        #b0b0b0 30px 50px);

        span {
            cursor: pointer;
            color: black;
        }

        i {
            color: black;
        }

        svg {
            color: black;
        }
    }
}

.preForeClosure__popup--detail {
    .ant-modal-title {
        font-size: 32px;
        font-weight: bold;
    }

    .editable-cell-value-wrap {
        font-weight: bold;
        color: black
    }

    .ant-table-thead {
        .ant-table-cell {
            background-color: white;
            font-weight: bold;
            font-size: 16px;
        }
    }

    .ant-table-tbody {
        .ant-table-cell {
            font-size: 14px;
        }
    }

    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: unset;
    }
}
