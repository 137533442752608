@import "../variables";

.ticket {
    &__list {
        background-color: white;
        height: 400px;
        overflow-y: scroll;
        padding: 10px 0;
        border-radius: 10px;
        -webkit-box-shadow: 1px 0 5px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 0 5px rgba(0, 0, 0, 0.2);

        &--unread {
            //position: absolute;
            margin-top: 15px;
            margin-left: 2px;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background-color: $color-primary-blur;
        }

        &--item {
            cursor: pointer;
            padding: 10px;

            .ant-list-item-meta-title {
                font-size: 18px;
            }

            &:hover {
                background-color: rgba(gray, 0.1);
            }
        }
    }

    &__detail {
        .ant-table-cell {
            font-size: 18px;
            padding-top: 0;
            padding-bottom: 0;
        }

        &--avatar {
            border: 1px solid rgba(gray, 0.1);
        }

        &--item {
            .ant-comment-inner {
                padding: 7px 0;
            }

            .ant-comment-content-author-name {
                width: 100%;
            }

            border: 1px solid rgba(gray, 0.3);
            border-radius: 10px;
            padding: 0 10px;
            margin-bottom: 15px;

            &-admin {
                border: 1px solid $color-primary-blur;
            }
        }

        &--content {
            max-height: 500px;
            overflow-y: scroll;
        }
    }

    &__footer {
        &--info {
            list-style: none;
        }

        &--item {
            display: inline;

            &-border {
                border-left: 1px solid gray;
                border-right: 1px solid gray;
                padding: 0 20px;
            }
        }

        &--wrapper {
            position: fixed;
            bottom: 0;
            right: 30px;
        }
    }
}
