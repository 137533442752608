@import "variables";

//============================ COLOR LAYOUT ========================================
.icon-ant {
    svg {
        margin-top: -5px;
    }
}

p {
    color: #999999;
}

//span {
//    color: #999999;
//}

div {
    color: #999999;
}

.ant-menu-title-content {
    color: #999999;

    a {
        font-size: $font-site-default !important;
    }
}

//button {
//    span {
//        color: white;
//    }
//}
//
//.ant-btn {
//    span {
//        color: rgba(0, 0, 0, 0.85) !important;
//    }
//}

.ant-table-column-title {
    color: rgba(0, 0, 0, 0.85) !important;
}

td {
    color: rgba(0, 0, 0, 0.85) !important;
}

//.ant-menu-dark .ant-menu-item > span > a {
//    color: #999999 !important;
//}

h1 {
    color: #999999;
}

h2 {
    color: #999999;
}

h3 {
    color: #999999;
}

h4 {
    color: #999999;
}

h5 {
    color: #999999;
}

h6 {
    color: #999999;
}

input, select, textarea {
    color: black;
}

textarea:focus, input:focus {
    color: black;
}

//============================ COLOR LAYOUT ========================================

.hn {
    &__tabs {
        &--custom-menu {
            .ant-tabs-nav {
                margin-bottom: 0;
                padding: 0 30px;

                &:before {
                    border-bottom: none;
                }
            }

            .ant-tabs-nav-wrap {
                background: white;
                padding: 0 30px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            .ant-tabs-nav-list {
                padding: 2px;
            }

            .ant-tabs-tab-btn {
                font-size: 16px;
            }
        }

        &-menu {
            .ant-tabs-tab {
                font-size: 18px;
            }

            .ant-tabs-nav {
                padding: 0 1rem !important;
            }
        }
    }

    &__content {
        background-color: #F4F6F8;

        &--title {
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
        }
    }

    &__navigation {
        &--icon {
            top: 50%;
            left: 50%;
            transform: translate(0%, 12%);
        }

        &--hide-all-submenu {
            .ant-menu-submenu-arrow {
                display: none;
            }
        }

        &--hide-all-popup-submenu {
            display: none;
        }

        &--banner-visit-website {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-bottom: 180px;
            margin-left: 5px;
        }

        &--footer-powered {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-bottom: 70px;
            margin-left: 5px;

            a {
                font-size: 14px !important;
                color: $color-gray;
            }
        }

        z-index: 10;
        position: fixed;

        .ant-menu-dark.ant-menu-submenu-popup {
            left: 55px !important;
        }

        .ant-menu.ant-menu-inline-collapsed {
            width: 60px;
        }

        .ant-menu {
            height: 100vh;
            background-color: white !important;
            -webkit-box-shadow: 15px 6px 38px -11px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 15px 6px 38px -11px rgba(0, 0, 0, 0.2) !important;
        }

        .ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-submenu-title {
            color: black !important;
        }

        .ant-menu-submenu-arrow {
            color: black !important;
        }

        .ant-menu-item {
            a {
                color: #999999 !important;
            }

            &:hover {
                border-left: 3px solid $color-primary-blur !important;

                a {
                    color: $color-primary-blur !important;
                }

                .icon {
                    &--arrow-right {
                        content: url("./layout/hover/arrow-right.svg");
                    }

                    &--link {
                        content: url("./layout/hover/link.svg");
                    }

                    &--link {
                        content: url("./layout/hover/arrow-right.svg");
                    }

                    &--contact-us {
                        content: url("./layout/navigation/hover/contact-us.svg");
                    }

                    &--home {
                        content: url("./layout/navigation/hover/home.svg");
                    }

                    &--off-market {
                        content: url("./layout/navigation/hover/off-market.svg");
                    }

                    &--plans-pricing {
                        content: url("./layout/navigation/hover/plans-pricing.svg");
                    }

                    &--exclusive-content {
                        content: url("./layout/navigation/hover/plans-pricing.svg");
                    }

                    &--support {
                        content: url("./layout/navigation/hover/support.svg");
                    }

                    &--faq {
                        content: url("./layout/navigation/hover/faq.svg");
                    }
                }
            }
        }

        .ant-menu-submenu {
            .ant-menu-submenu-arrow {
                &:before {
                    background-color: #999999 !important;
                }

                &:after {
                    background-color: #999999 !important;
                }
            }

            &:hover {
                border-left: 3px solid $color-primary-blur !important;

                span {
                    color: $color-primary-blur !important;
                }

                a {
                    color: $color-primary-blur !important;
                }

                .ant-menu-submenu-arrow {
                    &:before {
                        background-color: $color-primary-blur !important;
                    }

                    &:after {
                        background-color: $color-primary-blur !important;
                    }
                }

                .icon {
                    &--arrow-right {
                        content: url("./layout/hover/arrow-right.svg");
                    }

                    &--link {
                        content: url("./layout/hover/link.svg");
                    }

                    &--contact-us {
                        content: url("./layout/navigation/hover/contact-us.svg");
                    }

                    &--home {
                        content: url("./layout/navigation/hover/home.svg");
                    }

                    &--off-market {
                        content: url("./layout/navigation/hover/off-market.svg");
                    }

                    &--plans-pricing {
                        content: url("./layout/navigation/hover/plans-pricing.svg");
                    }

                    &--exclusive-content {
                        content: url("./layout/navigation/hover/plans-pricing.svg");
                    }

                    &--support {
                        content: url("./layout/navigation/hover/support.svg");
                    }

                    &--faq {
                        content: url("./layout/navigation/hover/faq.svg");
                    }
                }
            }
        }

        .ant-menu-item-selected {
            border-left: 3px solid $color-primary-blur !important;

            a {
                color: $color-primary-blur !important;
            }

            .icon {
                &--arrow-right {
                    content: url("./layout/hover/arrow-right.svg");
                }

                &--link {
                    content: url("./layout/hover/link.svg");
                }

                &--contact-us {
                    content: url("./layout/navigation/hover/contact-us.svg");
                }

                &--home {
                    content: url("./layout/navigation/hover/home.svg");
                }

                &--off-market {
                    content: url("./layout/navigation/hover/off-market.svg");
                }

                &--plans-pricing {
                    content: url("./layout/navigation/hover/plans-pricing.svg");
                }

                &--exclusive-content {
                    content: url("./layout/navigation/hover/plans-pricing.svg");
                }

                &--support {
                    content: url("./layout/navigation/hover/support.svg");
                }

                &--faq {
                    content: url("./layout/navigation/hover/faq.svg");
                }
            }
        }

        .ant-menu-submenu-selected {
            border-left: 3px solid $color-primary-blur !important;

            span {
                color: $color-primary-blur !important;
            }

            .ant-menu-submenu-arrow {
                &:before {
                    background-color: $color-primary-blur !important;
                }

                &:after {
                    background-color: $color-primary-blur !important;
                }
            }

            .icon {
                &--arrow-right {
                    content: url("./layout/hover/arrow-right.svg");
                }

                &--link {
                    content: url("./layout/hover/link.svg");
                }

                &--contact-us {
                    content: url("./layout/navigation/hover/contact-us.svg");
                }

                &--home {
                    content: url("./layout/navigation/hover/home.svg");
                }

                &--off-market {
                    content: url("./layout/navigation/hover/off-market.svg");
                }

                &--plans-pricing {
                    content: url("./layout/navigation/hover/plans-pricing.svg");
                }

                &--exclusive-content {
                    content: url("./layout/navigation/hover/plans-pricing.svg");
                }

                &--support {
                    content: url("./layout/navigation/hover/support.svg");
                }

                &--faq {
                    content: url("./layout/navigation/hover/faq.svg");
                }
            }
        }

        //.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
        //    background-color: black !important;
        //}
        //
        //.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
        //    background-color: black !important;
        //}

        &--mobile {
            .ant-menu {
                height: 100vh;
                background-color: white;
            }

            .ant-drawer-header {
                background-color: white;
            }

            .ant-drawer-body {
                padding: 0;
            }

            .ant-drawer-close {
                color: white;
            }

            .ant-menu-title-content {
                color: black;
            }

            .ant-menu-submenu-arrow {
                &:after {
                    background: black !important;
                }

                &:before {
                    background: black !important;
                }
            }

            .ant-menu-item-selected {
                background-color: white !important;
                color: $color-primary-blur !important;
            }
        }
    }
}

.icon {
    &--phone {
        content: url("./layout/icon/phone.svg");

        &:hover {
            content: url("./layout/icon/hover/phone.svg");
        }
    }

    &--faq {
        content: url("./layout/icon/faq.svg");

        &:hover {
            content: url("./layout/icon/hover/faq.svg");
        }
    }

    &--send {
        content: url("./layout/icon/email.svg");

        &:hover {
            content: url("./layout/icon/hover/email.svg");
        }
    }

    &--location {
        content: url("./layout/icon/location.svg");

        &:hover {
            content: url("./layout/icon/hover/location.svg");
        }
    }

    &--arrow-right {
        content: url("layout/arrow-right.svg");

        &:hover {
            content: url("./layout/hover/arrow-right.svg");
        }
    }

    &--link {
        content: url("layout/link.svg");

        &:hover {
            content: url("./layout/hover/link.svg");
        }
    }

    &--avatar-user {
        content: url("layout/avatar.svg");
    }

    &--contact-us {
        content: url("./layout/navigation/contact-us.svg");

        &:hover {
            content: url("./layout/navigation/hover/contact-us.svg");
        }
    }

    &--home {
        content: url("./layout/navigation/home.svg");

        &:hover {
            content: url("./layout/navigation/hover/home.svg");
        }
    }

    &--off-market {
        content: url("./layout/navigation/off-market.svg");

        &:hover {
            content: url("./layout/navigation/hover/off-market.svg");
        }
    }

    &--plans-pricing {
        content: url("./layout/navigation/plans-pricing.svg");

        &:hover {
            content: url("./layout/navigation/hover/plans-pricing.svg");
        }
    }

    &--exclusive-content {
        content: url("./layout/navigation/plans-pricing.svg");

        &:hover {
            content: url("./layout/navigation/hover/plans-pricing.svg");
        }
    }

    &--support {
        content: url("./layout/navigation/support.svg");

        &:hover {
            content: url("./layout/navigation/hover/support.svg");
        }
    }
}

.hn {
    &__form {
        &__custom {
            .ant-form-item {
                position: relative;
            }

            .ant-form-item-label {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 999;
                font-size: 10px;
                //background-color: white;
                margin-top: -14px;
                margin-left: 19px;
                padding-bottom: 0;
                padding-right: 10px;
            }

            .ant-form-item-required {
                font-size: 15px !important;
                color: $color-primary-blur !important;
            }

            .ant-form-item-label {
                label {
                    font-size: 15px !important;
                    color: $color-primary-blur !important;
                }
            }

            .ant-form-item-control-input-content {
                input {
                    padding: 16px 11px !important;
                    border-radius: 5px;
                }
            }
        }
    }
}

.menu {
    &__support {
        &--count {
            background-color: $color-primary-blur;
            border-radius: 5px;
            font-size: 14px;
            color: white;
            font-weight: bold;
            padding: 2px 7px;
        }
    }
}

.select-custom-style {
    position: relative;

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #d9d9d9;
        box-shadow: none;
    }

    .ant-select:not(.ant-select-disabled):focus .ant-select-selector {
        border-color: #d9d9d9;
        box-shadow: none;
    }

    .ant-select:not(.ant-select-disabled):active .ant-select-selector {
        border-color: #d9d9d9;
        box-shadow: none;
    }

    &--icon-clear {
        position: absolute;
        right: 0;
        top: 60%;
        transform: translateY(-60%);
        margin-right: 25px;
    }

    .ant-badge-count {
        background: #686868;
        border-radius: 4px;
        font-weight: bold;
    }

    &--icon-count {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 43px;
    }

    //.ant-select-clear {
    //    right: 28px;
    //    font-size: 15px;
    //    margin-top: -8px;
    //}

    &--icon-arrow {
        position: absolute;
        right: 0;
        top: 60%;
        transform: translateY(-60%);
        margin-right: 10px;
    }
}

@include screen-small {

}

@include screen-medium {

}

@include screen-lg {
    .ant-scrolling-effect {
        overflow: unset !important;
    }
    * {
        p {
            font-size: 18px !important;
        }

        a {
            font-size: 18px !important;
        }

        label {
            font-size: 18px !important;
        }

        .ant-popover-buttons {
            .ant-btn {
                span {
                    font-size: 14px !important;
                }
            }
        }

        .ant-btn {
            padding: 0 15px !important;

            span {
                font-size: 18px !important;
            }
        }
    }
    .ant-menu-vertical {
        background-color: #123963 !important;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: unset !important;
    }
    .ant-menu-submenu-popup {
        left: 230px !important;
    }
    .custom-table {
        .ant-table-cell {
            padding: 0 16px;
            font-size: 18px;
        }
    }
    .hn {
        &__content {
            //height: 100vh;
            transition-property: margin-left;
            transition-timing-function: cubic-bezier(.075, .82, .165, 1);
            transition-duration: .05s;
            margin-left: 230px;
            //margin-top: 72px;

            &--expanded {
                margin-left: 65px;
            }
        }

        &__navigation {

            &--mobile {
                display: none;
            }
        }
    }
}

@include screen-max-lg {
    .max {
        &__width {
            &--400px {
                width: 400px !important;
            }
        }
    }
}
