@import "../variables";

.ant-select-item-option-content {
    color: black
}

.ant-select-selection-item {
    color: black
}

.plans-pricing {
    &__tabs-state {
        width: 1250px;
        margin: auto;
        height: 60px;
        overflow-x: scroll;
        white-space: nowrap;
    }

    &__segment {
        .ant-radio-group {
            background-color: white;
            padding: 3px;
            border-radius: 15px;
            //-webkit-box-shadow: 1px 1px 9px -4px #6a6a6a;
            //box-shadow: 1px 1px 9px -4px #6a6a6a;
        }

        .ant-radio-button-wrapper {
            border: none;
            border-radius: 15px;
            width: 145px
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
            width: 0;
            border-radius: 15px;
        }

        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background-color: $color-primary-blur;
            border: none;
            border-radius: 15px;
        }
    }

    &__packages {
        &--item-type2 {
            &:hover {
                border: 1px solid rgba(white, 0.8) !important;
            }
        }

        &--item {
            //background-color: rgba($color-primary-blur, 0.1);
            background-color: white;
            border-radius: 10px;
            padding: 20px;
            //-moz-box-shadow: 1px 1px 9px -4px #6a6a6a;
            //-webkit-box-shadow: 1px 1px 9px -4px #6a6a6a;
            //-o-box-shadow: 1px 1px 9px -4px #6a6a6a;
            //box-shadow: 1px 1px 9px -4px #6a6a6a;

            .list-style-none {
                p {
                    color: $color-black;
                    font-weight: bold;
                }

                //i {
                //    color: $color-primary-blur;
                //}

            }

            &-premium {
                //background-color: $color-primary !important;

                //.plans-pricing__packages--title-content {
                //    p {
                //        color: white !important;
                //    }
                //}

                .plans-pricing__packages--title-price {
                    //background-color: $color-primary-blur;
                }

                .list-style-none {
                    i {
                        color: $color-primary-blur !important;
                    }

                    //p {
                    //    color: white !important;
                    //}
                }
            }

            &:hover {
                border: 1px solid rgba($color-primary-blur, 0.8);
            }
        }

        &--title {
            &-content {
                //padding: 10px;
            }

            &-price {
                line-height: 1.2;
                //padding: 10px;
                //background-color: $color-primary-blur;
                border-radius: 10px;

                p {
                    color: $color-black
                }
            }
        }

        &--select {
            .ant-select-clear {
                background-color: transparent !important;
            }

            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                border-radius: 6px;
                background-color: transparent;
                padding: 6px;
            }

            .ant-select-selection-item {
                background-color: transparent;
                border-color: $color-primary-blur;
                color: $color-primary-blur;
                border-radius: 7px;
            }

            .anticon-close {
                svg {
                    margin-bottom: 4px;
                }
            }
        }

        &--submit {
            background-color: rgba($color-primary-blur, 0.8);
            font-size: 18px;
            border-radius: 10px;
            text-align: center;
            color: white;
            padding: 18px 0;
            cursor: pointer;
            font-weight: bold;
        }

        &--submit-type2 {
            background-color: white;
            font-size: 18px;
            border-radius: 10px;
            text-align: center;
            color: $color-primary;
            border: 1px solid $color-primary;
            padding: 18px 0;
            cursor: pointer;
            font-weight: bold;

            &-disabled {
                background-color: $color-primary-blur;
                color: #C4C4C4;
                border: 1px solid #C4C4C4;
            }
        }

        &--banner-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 500px;
            height: 400px;
            margin-left: 90px;
            margin-top: 30px;
        }
    }

    &__contact {
        cursor: pointer;

        &:hover {
            border-radius: 10px;
            background-color: #FCCE01;
            padding: 24px;

            p {
                color: white;
                line-height: 1;
            }
        }

        border: 1px solid #FCCE01;
        border-radius: 10px;
        background-color: white;
        padding: 24px;

        p {
            color: #FCCE01;
            line-height: 1;
        }
    }

    &__policy {
        cursor: pointer;

        &:hover {
            border-radius: 10px;
            border: 1px solid #FCCE01;
            background-color: white;
            padding: 24px;

            p {
                color: #FCCE01;
                line-height: 1;
            }
        }

        //border: 1px solid #FCCE01;
        border-radius: 10px;
        background-color: #FCCE01;
        padding: 24px;

        p {
            color: white;
            line-height: 1;
        }
    }
}

@include screen-small {
    .plans-pricing {
        &__packages {
            &--title {
                .fs--24px {
                    font-size: 12px !important;
                }
            }
        }
    }
}

@include screen-medium {

}

@include screen-lg {

}
