@import "variables";

.hn {
    &__header {
        &--notify {
            position: absolute;
            top: 0;
            right: 0;
            width: 400px;
            height: 400px;
            overflow-y: scroll;
            background-color: white;
            z-index: 999999;
            margin-top: 35px;
            margin-right: 10px;

            &-item {
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                }
            }
        }

        .ant-avatar-string {
            padding-top: 5px;
        }

        //overflow: hidden;
        /* ff 3.6+ */
        //background:-moz-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        //
        ///* safari 5.1+,chrome 10+ */
        //background:-webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        //
        ///* opera 11.10+ */
        //background:-o-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        //
        ///* ie 6-9 */
        //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#123963', endColorstr='#FFFFFF', GradientType=0 );
        //
        ///* ie 10+ */
        //background:-ms-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        //
        ///* global 94%+ browsers support */
        //background:linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(219, 247, 255, 1) 35%, rgba(0, 164, 203, 1) 77%, rgba(18, 57, 99, 1) 100%);
        background-color: $color-primary;
        color: #b2b2b2;
        //height: 72px;
        text-align: center;
        width: 100%;
        z-index: 99;
        position: sticky;
        top: 0;
        -webkit-box-shadow: -50px 10px 26px -8px rgba(0, 0, 0, 0.3);
        box-shadow: -50px 10px 26px -8px rgba(0, 0, 0, 0.3);

        &--logo {
            //width: 155px;
        }
    }
}

@include screen-small {
    .hn {
        &__header {
            &--logo {
                img {
                    width: 145px;
                }
            }

            &--toggle {
                font-size: 30px;
            }
        }
    }
}

@include screen-medium {

}

@include screen-lg {

}

@include screen-max-lg {

}
