@import "../variables";

.my-subscription {
    &__card {
        div {
            color: black;
        }

        p {
            padding: 0;
            margin-bottom: 5px;
        }

        border-radius: 10px;

        .ant-card-head {
            border-bottom: 0;
        }

        .ant-card-head-wrapper {
            border-bottom: 1px solid $color-gray;
        }

        .ant-card-head-title {
            font-size: 20px;
        }

        &--btn-cancel-renewal {
            //border: 1px solid $color-gray;
            //background-color: #00A1C9;
            background-color: #C4C4C4;
            color: white;

            &-disabled {
                color: white;
                background-color: #C4C4C4;

                &:hover {
                    color: white;
                    //background-color: #C4C4C4;
                }
            }

            &:hover {
                color: white;
                background-color: #00A1C9;
            }
        }

        &--slide {
            border-radius: 10px;
            overflow: hidden;

            .ant-card-body {
                padding: 0;
            }
        }
    }

    &__invoice {
        &--alert {
            background-color: rgba($color-primary-blur, 0.8);
            border: none;
            padding: 18px 20px;
            border-radius: 10px;

            .ant-alert-message {
                color: white;
                font-size: 14px;

                svg {
                    margin-top: -6px;
                }
            }

            .ant-alert-icon {
                display: none;
            }
        }

        &--pagination {
            .ant-pagination-options {
                display: none;
            }
        }

        &--list {
            .ant-table-thead {
                .ant-table-cell {
                    &:before {
                        content: none !important;
                    }

                    background-color: white;
                    font-weight: bold;
                    font-size: 16px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .ant-table-tbody {
                .ant-table-cell {
                    padding: 5px 0;
                    font-size: 14px;
                }
            }
        }
    }

    &--item-month {
        content: " ";
        width: 100%;
        height: 100%;
    }

    &--price {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px;

        &-hint {
            color: rgba(0, 0, 0, 0.3);
            font-size: 15px !important;
        }
    }

    &--btn-buy_now {
        padding: 8px 50px;
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }
    }

    &--calendar {
        //.ant-picker-cell-selected .ant-picker-calendar-date-today {
        //    border-color: #f0f0f0 !important;
        //    background: none !important;
        //}
        .ant-picker-calendar-date {
            border-color: #f0f0f0 !important;
            background: none !important;
        }

        .ant-picker-calendar-date-value {
            color: rgba(0, 0, 0, 0.85) !important;
        }

        td {
            &:hover {
                background-color: #f0f0f0
            }
        }
    }

    &__detail {
        &__package {
            &--wrapper {
                border-color: rgba(gray, 0.4);
                border-style: solid;
                border-width: 1px 0 1px 0;
            }

            //&--item {
            //    border-left: 1px solid rgba(gray, 0.4);
            //    padding: 15px 0;
            //
            //    &:last-child {
            //        border-right: 1px solid rgba(gray, 0.4);
            //    }
            //}

            &--content {
                .ant-tree-switcher-icon {
                    color: $color-foreign;
                }

                .ant-tree-treenode {
                    width: 100%;
                    //border-top: 1px solid rgba(gray, 0.4);
                    padding: 10px;
                }
            }
        }
    }

    &__cancel-renewal {

        &--form-option-3 {
            .ant-radio-checked .ant-radio-inner {
                border-color: $btn-color-primary;
            }

            .ant-radio-checked::after {
                border-color: $btn-color-primary;
            }

            .ant-radio-inner::after {
                background-color: $btn-color-primary;
            }

            textarea {
                border-radius: 10px;
            }

            .ant-radio:hover {
                .ant-radio-inner {
                    border-color: #999999;

                    &:after {
                        background-color: #999999;
                        transform: scale(0.5);
                        opacity: 1;
                        transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)
                    }
                }
            }

        }

        &--form-option-2 {
            .ant-radio-button-wrapper {
                border-radius: 10px;
                height: unset;
                padding: 8px 24px;
                border-width: 1px;
                border-color: #999999;
                color: #999999;
                font-weight: bold;

                &:hover {
                    border-color: $btn-color-primary;
                    color: $btn-color-primary
                }

                &:before {
                    width: 0;
                    height: 0;
                }
            }

            .ant-radio-button-wrapper-checked {
                background-color: $btn-color-primary;
                color: white !important;
                border: none;
            }

            .ant-radio-button-wrapper:not(:last-child) {
                margin-right: 13px;
            }

            .ant-radio-group {
                width: 100%;
                text-align: center;
            }
        }

        &__wrapper {
            //top: 36px !important;
            border: 1px solid #d9d9d9;
            border-bottom-left-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
            box-shadow: none;

            .ant-select-item {
                padding: 8px 12px;
            }

            .ant-select-item-option-state {
                display: none;
            }

            .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
                box-shadow: none;
            }
        }

        &--select-item {
            color: #686868;

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: $color-primary-blur;
                border-color: $color-primary-blur;
            }
        }

        &--select {
            .ant-select-selection {
                box-shadow: none;
            }

            .ant-select-multiple .ant-select-selection-item {
                background: #C4C4C4;
                border-color: #C4C4C4;
                color: #686868;
                border-radius: 4px;
            }

            .ant-select-selector {
                padding: 6px 10px;
                border-radius: 8px !important;
                //border-top-left-radius: 8px !important;
                //border-top-right-radius: 8px !important;
                border-width: 1px !important;
            }

            .ant-select-focused .ant-select-selector,
            .ant-select-selector:focus,
            .ant-select-selector:active,
            .ant-select-open .ant-select-selector {
                border-color: #C4C4C4 !important;
                box-shadow: none !important;
            }


            svg {
                margin-top: -5px;
            }
        }

        &--back {
            border-color: unset !important;
            background-color: #C4C4C4 !important;
            box-shadow: unset !important;
            color: #FFFFFF;

            &:hover {
                border-color: unset !important;
                background-color: $btn-color-primary !important;
                box-shadow: unset !important;
            }

            &:active {
                border-color: unset !important;
                background-color: $btn-color-primary !important;
                box-shadow: unset !important;
            }

            &:focus {
                border-color: unset !important;
                background-color: $btn-color-primary !important;
                box-shadow: unset !important;
            }
        }

        &--process {
            border-color: unset;
            background-color: $color-primary-blur;
            box-shadow: unset !important;

            &:hover {
                border-color: unset !important;
                background-color: #095F93 !important;
                box-shadow: unset !important;
            }

            &:active {
                border-color: unset !important;
                background-color: #095F93 !important;
                box-shadow: unset !important;
            }

            &:focus {
                border-color: unset !important;
                background-color: #095F93 !important;
                box-shadow: unset !important;
            }
        }
    }

    &__slide {
        -webkit-box-shadow: 1px 1px 9px -4px #000000;
        box-shadow: 1px 1px 9px -4px #000000;
        border-radius: 10px;
        overflow: hidden;

        &--popup {
            .ant-modal-body {
                padding-top: 30px;
                height: 730px;
                background-image: url("../layout/home-side-popup.png");
                background-size: 100% 730px;
                background-repeat: no-repeat;
            }

            p {
                color: black;
            }
        }

        &--contact-us {
            background-color: $btn-color-primary;
            border-radius: 5px;
            box-shadow: none;
            border: none;
            color: white;
            font-weight: bold;
            font-size: 20px;
            padding: 10px 40px;
        }

        div {
            display: block !important;
        }
    }
}

@include screen-small {
    .my-subscription {
        &__detail {
            &__package {
                &--wrapper {
                    border-color: rgba(gray, 0.4);
                    border-style: solid;
                    border-width: 1px;
                }

                &--item {
                    padding-top: 15px;
                    padding-bottom: 10px;

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(gray, 0.4);
                    }

                    //border-left: 1px solid rgba(gray, 0.4);
                    //padding: 15px 0;
                    //
                    //&:last-child {
                    //    border-right: 1px solid rgba(gray, 0.4);
                    //}
                }

            }
        }
    }
}

@include screen-medium {
    .my-subscription {
        &__detail {
            &__package {
                &--title {
                    height: 90px;
                }

                &--item {
                    border-left: 1px solid rgba(gray, 0.4);
                    padding: 15px 0;

                    &:last-child {
                        border-right: 1px solid rgba(gray, 0.4);
                    }
                }

            }
        }
    }
}

@include screen-lg {
    .my-subscription {
        &__detail {
            &__package {
                &--title {
                    height: 90px;
                }

                &--item {
                    border-left: 1px solid rgba(gray, 0.4);
                    padding: 15px 0;

                    &:last-child {
                        border-right: 1px solid rgba(gray, 0.4);
                    }
                }

            }
        }
    }
}
