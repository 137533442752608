#root{
  /*height: 100vh;*/
  /*padding-top: 10%;*/
}

.pre-fore-closure {
  text-align: center;
  min-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .pre-fore-closure-logo {
    animation: pre-fore-closure-logo-spin infinite 20s linear;
  }
}

@keyframes pre-fore-closure-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
