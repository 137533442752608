@import "../variables";

.contact-us {
    &__info {
        &--item {
            -webkit-box-shadow: 1px 1px 9px -4px #000000;
            box-shadow: 1px 1px 9px -4px #000000;
            border-radius: 10px;
            //color: $color-primary-blur;
            background-color: #FFFFFF;

            &-description {
                font-size: 16px !important;
            }

            p {
                color: $color-primary-blur
            }

            &:hover {
                background-color: $color-primary-blur;

                p {
                    color: white;
                }

                .icon {
                    &--phone {
                        content: url("../layout/icon/hover/phone.svg");
                    }

                    &--send {
                        content: url("../layout/icon/hover/email.svg");
                    }

                    &--location {
                        content: url("../layout/icon/hover/location.svg");
                    }
                }

                p {
                    color: white
                }
            }
        }

        &--open {
            border-left: 9px solid $color-foreign;
            padding-left: 10px;
        }
    }

    &__maps {
        -webkit-box-shadow: 1px 1px 9px -4px #000000;
        box-shadow: 1px 1px 9px -4px #000000;
        padding: 4px;
        border-radius: 6px;
    }

    &__slide {
        -webkit-box-shadow: 1px 1px 9px -4px #000000;
        box-shadow: 1px 1px 9px -4px #000000;
        border-radius: 10px;
        overflow: hidden;
    }
}
