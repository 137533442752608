@import "../variables";

.exclusive-content {
    &--popup {
        .ant-modal-close {
            margin-right: -35px;
            margin-top: -30px;
            color: white
        }

        .ant-modal-close-icon {
            font-size: 25px;
        }

        .ant-modal-content {
            background-color: transparent;
        }

        .ant-modal-body {
            padding: 0;
        }
    }

    &--title {
        color: $color-primary-blur;
        font-size: 24px !important;
        font-weight: bold;

        &-tag {
            border-left: 3px solid $color-foreign;
            padding-left: 10px;
        }

        &-short {
            font-weight: bold;
            font-size: 16px !important;
        }
    }

    &__item {
        overflow: hidden;
        background-color: white;
        padding: 10px 15px;
        border-radius: 6px;
        -webkit-box-shadow: 0px 5px 14px -9px rgba(0, 0, 0, 0.82);
        box-shadow: 0px 5px 14px -9px rgba(0, 0, 0, 0.82);
        height: 495px;

        &--title {
            margin: 10px 0;
            color: $color-primary;
            font-weight: bold;
        }

        &--description {
            color: $color-primary-blur;
            font-size: 14px !important;
            height: 47px;
            overflow: hidden;
        }

        &--img {
            overflow: hidden;
            transition: transform .2s; /* Animation */
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
