@import "../variables";

.package-subscription {
    &--item-month {
        content: " ";
        width: 100%;
        height: 100%;
    }

    &--price {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px;

        &-hint {
            color: rgba(0, 0, 0, 0.3);
            font-size: 15px !important;
        }
    }

    &--btn-buy_now {
        padding: 8px 50px;
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }
    }

    &--calendar {
        //.ant-picker-cell-selected .ant-picker-calendar-date-today {
        //    border-color: #f0f0f0 !important;
        //    background: none !important;
        //}
        .ant-picker-calendar-date {
            border-color: #f0f0f0 !important;
            background: none !important;
        }

        .ant-picker-calendar-date-value {
            color: rgba(0, 0, 0, 0.85) !important;
        }

        td {
            &:hover {
                background-color: #f0f0f0
            }
        }
    }

    &__detail {
        .ant-tree-switcher-icon {
            svg {
                color: $color-foreign;
            }
        }

        .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
            background-color: rgba($color-foreign, 0.4);
            border-color: $color-foreign;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: rgba($color-foreign, 0.4);
            border-color: $color-foreign;
        }

        &__package {
            &--wrapper {
                border-color: rgba(gray, 0.4);
                border-style: solid;
                border-width: 1px 0 1px 0;
            }

            &--item {
                border-left: 1px solid rgba(gray, 0.4);
                padding: 15px 0;

                &:last-child {
                    border-right: 1px solid rgba(gray, 0.4);
                }
            }

            &--content {
                .ant-tree-switcher-icon {
                    color: $color-foreign;
                }

                .ant-tree-treenode {
                    width: 100%;
                    //border-top: 1px solid rgba(gray, 0.4);
                    padding: 10px;
                }
            }

            &--order {
                border: 1px solid rgba(gray, 0.2);
                padding: 15px 0;

                &-title {
                    text-align: center;
                    position: relative;

                    &-btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin-right: 15px;
                    }
                }
            }
        }

        &__modal-confirm-package {
            &--standard {
                border-top: 6px solid $color-primary !important;
            }

            &--premium {
                border-top: 6px solid $color-foreign !important;
            }
        }
    }

    &__content {
        &--list-packages {
            margin-left: -15px;

            &-title {
                margin-left: 10px;
            }
        }

        &--item-package {

        }
    }
}

@include screen-small {
    .package-subscription {

        &__content {
            &--item-package {
                margin-bottom: 20px;
                //display: inline-block;
                //float: none;
                //width: 300px;
                //min-width: 300px;
            }
        }
    }
}

@include screen-medium {

}

@include screen-lg {

}
