.preForeClosure {
    &__popup {
        &--detail {
            .ant-modal-body {
                padding-top: 0;
            }
        }
    }
}

.off-market-filters {

    &--select-action {
        .ant-select-selector {
            border-color: #00A1C9 !important;
            height: 41px !important;
            padding: 5px 11px !important;

            .ant-select-selection-item {
                font-weight: bold;
                color: #00A1C9 !important;
            }

            .ant-select-selection-placeholder {
                font-weight: bold;
                color: #00A1C9 !important;
            }
        }
    }

    &__wrapper {
        .ant-select-selector {
            border-radius: 4px !important;
        }

        .ant-select-multiple .ant-select-selector {
            padding: 6px 4px;
        }
    }

    &--submit {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        padding: 0 35px;
        padding-bottom: 20px;
        background-color: white;
    }

    input {
        border-radius: 4px;
    }

    .ant-radio-wrapper {
        span {
            font-size: 14px;
        }
    }

    .ant-radio-inner::after {
        background-color: #00A1C9 !important;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #00A1C9 !important;
    }

    &--datepicker-range {
        .ant-picker {
            padding-left: 0;
            padding-right: 0;
        }

        .ant-picker-range-separator {
            .anticon {
                display: none;
            }
        }

        .ant-picker-input {
            border: 1px solid #C4C4C4;
            font-size: 14px;
            border-radius: 4px;
            padding: 6px;
            padding-left: 30px;
        }

        .ant-picker-suffix {
            display: none;
        }
    }

    .ant-form-item-label {
        label {
            font-size: 14px !important;
        }

        margin-bottom: 4px;
    }

    .ant-collapse-content-box {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &--property-type {
        .ant-checkbox-checked::after {
            border: 1px solid #00A1C9 !important;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #00A1C9 !important;
            border: #00A1C9 !important;
        }

        .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(45deg) scale(1) translate(-42%, -60%) !important;
        }
    }
}
