@import "../variables";

.hn {
    &__page-content {

        .custom-table {
            background-color: white;
            padding-bottom: 10px;
            padding-top: 5px;
            border-radius: 10px;
        }

        &--title {
            background-color: white;
            padding-left: 30px !important;
            margin: 0 16px;
            font-size: 20px;
            color: black;
        }

        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
            background-color: white;
        }

        .ant-rate-star-full {
            color: $color-foreign;

            .ant-rate-star-first, .ant-rate-star-second {
                color: $color-foreign;
            }
        }

        .ant-tabs-nav {
            padding: 0 30px;

            &:before {
                border-bottom: none;
            }
        }

        .ant-tabs-nav-wrap {
            background: white;
            padding: 0 30px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        .ant-tabs-nav-list {
            padding: 2px;
        }

        .ant-tabs-tab-btn {
            font-size: 16px;
        }

        //.ant-table {
        //    border-radius: 10px;
        //}
        //
        //.ant-table-container table > thead > tr:first-child th:first-child {
        //    border-top-left-radius: 10px;
        //}
        //
        //.ant-table-container table > thead > tr:first-child th:last-child {
        //    border-top-right-radius: 10px;
        //}

        .ant-table-thead {
            .ant-table-cell {
                background-color: white;
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 20px;
                font-weight: bold;
            }
        }

        .ant-table-tbody > tr {
            &:last-child {
                > td {
                    border-bottom: none;
                }
            }
        }

        .ant-table-tbody {
            tr {
                border-bottom: none;
            }

            .ant-table-cell {
                svg {
                    &:hover {
                        color: $color-primary-blur
                    }
                }
            }
        }
    }
}

.filter-component {
    background-color: white;
    margin: 0 14px;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px 25px 10px 25px;

    .ant-select-selection-item-remove {
        svg {
            margin-top: -5px;
        }
    }
}

.custom-pagination {
    &--current-page-size {
        margin-right: -5px;
    }

    .ant-space-item {
        font-size: 14px;
        color: $color-primary-blur;
    }

    .ant-dropdown-trigger {
        text-decoration: none;

        svg {
            margin-top: -5px;
            margin-left: -5px;
        }
    }

    .ant-pagination-next {
        svg {
            margin-top: -5px;
        }
    }

    .ant-pagination-prev {
        svg {
            margin-top: -5px;
        }
    }

    .ant-pagination-options {
        float: right;

        .ant-pagination-options-quick-jumper {
            color: black;
        }
    }
}
