@import "../variables";

.off-market {
    &__button {
        &--action {
            font-size: 14px;
            background-color: white;
            border: 1px solid $color-primary-blur;
            border-radius: 5px;
            color: $color-primary-blur;
            padding: 2px 10px;

            &:hover {
                background-color: $color-primary-blur;
                color: white;
            }
        }
    }
}
