$color-primary: #01579F;
//$color-primary: #173D60;
$color-danger: #FC304B;
$color-primary-blur: #00B7E3;
$color-foreign: #F7C830;
$color-gray: #999999;
$color-black: #222222;

$btn-color-primary: $color-primary-blur;
$btn-color-danger: #ff4d4f;

$font-site-default: 20px;

@mixin screen-small {
    @media (max-width: 450px) {
        @content;
    }
}

@mixin screen-medium {
    @media (min-width: 450px) and (max-width: 767px) {
        @content;
    }
}

@mixin screen-lg {
    @media (min-width: 767px) {
        @content;
    }
}

@mixin screen-max-lg {
    @media (min-width: 1800px) {
        @content;
    }
}
