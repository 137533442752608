@import "../variables";
//* {
//    box-sizing: border-box;
//}

//body {
//    background: #f6f5f7;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    flex-direction: column;
//    font-family: 'Montserrat', sans-serif;
//    height: 100vh;
//    margin: -20px 0 50px;
//}
.forgot {
    &--wrapper-form {
        .ant-form-item-required {
            &:before {
                color: $color-primary-blur !important;
            }
        }

        .ant-btn-primary[disabled] {
            background-color: #C4C4C4;
            border: none;

            span {
                color: white;
            }

            &:hover {
                background-color: #C4C4C4 !important;
            }
        }
    }

    &--countdown {
        .ant-statistic-content-value {
            color: $color-primary-blur !important
        }
    }
}

.login {
    &--hint {
        position: absolute;
        bottom: 0;
        font-size: 10px !important;
        color: $color-gray !important;
        margin-bottom: 10px !important;
    }
}

.login_container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.login_container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

.sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.login_container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.login_container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.overlay {
    background: #123963;
    background: -webkit-linear-gradient(to top, #123963, #01A0C6);
    background: linear-gradient(to top, #123963, #01A0C6);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.login_container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.login_container.right-panel-active .overlay-left {
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.login_container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
}

.btn_init_page {
    position: absolute;
    bottom: 0;
    margin-bottom: 60px;
}


.btn_sigup_page {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
}

.login {
    &__form {
        //margin-top: 10%;

        h1 {
            font-weight: bold;
            margin: 0;
        }

        h2 {
            text-align: center;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
            //margin: 20px 0 30px;
            font-weight: 300;
            color: white;
        }

        //span {
        //    font-size: 12px;
        //}

        a {
            color: #333;
            font-size: 14px;
            text-decoration: none;
            margin: 15px 0;
        }

        button {
            border-radius: 20px;
            border: 1px solid #123963;
            background-color: #123963;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: bold;
            padding: 12px 45px;
            letter-spacing: 1px;
            text-transform: uppercase;
            transition: transform 80ms ease-in;
        }

        button:active {
            transform: scale(0.95);
        }

        button:focus {
            outline: none;
        }

        button.ghost {
            background-color: transparent;
            border-color: #FFFFFF;
        }

        form {
            background-color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            //padding: 0 40px;
            height: 100%;
            text-align: center;
        }

        input {
            background-color: #eee;
            border: none;
            padding: 12px 15px;
            margin: 8px 0;
            width: 100%;
        }
    }
}

.hn {
    &__login {
        height: 100vh;
        padding-top: 7%;
    }
}


.hn {
    &__login {
        height: 100vh;
        padding-top: 7%;
    }

    &__authentication {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;

        p{
            color: white;
        }

        .ant-result-title{
            color: white !important;
        }

        .ant-result-subtitle{
            color: white !important;
        }

        &--screen {
            border-radius: 8px;
            background-color: white;
            position: relative;
            height: 330px;
            width: 360px;
            box-shadow: 0px 0px 24px #9b9b9b;
        }

        &--login {
            width: 320px;
            padding: 30px;
            padding-top: 156px;
        }

        &--login__field {
            padding: 20px 0px;
            position: relative;
        }

        &--login__icon {
            position: absolute;
            top: 30px;
            color: $color-primary-blur;
        }

        &--login__input {
            border: none;
            border-bottom: 2px solid #D1D1D4;
            background: none;
            padding: 10px;
            padding-left: 24px;
            font-weight: 700;
            width: 75%;
            transition: .2s;
        }

        &--login__input:active,
        &--login__input:focus,
        &--login__input:hover {
            outline: none;
            border-bottom-color: $color-primary-blur;
        }

        &--login__submit {
            text-decoration: none;
            background: #fff;
            font-size: 14px;
            margin-top: 30px;
            padding: 16px 20px;
            border-radius: 26px;
            border: 1px solid #D4D3E8;
            text-transform: uppercase;
            font-weight: 700;
            display: flex;
            align-items: center;
            width: 100%;
            color: $color-primary-blur;
            box-shadow: 0px 2px 2px $color-primary-blur;
            cursor: pointer;
            transition: .2s;
        }

        &--login__submit:active,
        &--login__submit:focus,
        &--login__submit:hover {
            border-color: $color-primary-blur;
            outline: none;
        }

        .button__icon {
            font-size: 24px;
            margin-left: auto;
            color: $color-primary-blur;
        }
    }
}
