@import "../variables";

.profile {
    &--upload {
        .ant-upload {
            height: 100%;
            width: 100%;
            background-color: white;
            border: none;
            -webkit-box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.13);
            box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.13);
            border-radius: 10px;
        }
        background-color: #F8F9FA;
    }

    &__title {
        &--avatar {
            font-size: $font-site-default;
        }
    }

    &__wrap {
        position: relative;
        width: 11vw;
        height: 11vw;
        max-width: 190px;
        max-height: 190px;
        overflow: hidden;
        border-radius: 50%;
        &--avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
