@import "../variables";


@keyframes collapse-open {
    0% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes collapse-close {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(90deg);
    }
}

.faq {
    &__content {
        &--link {
            font-size: 14px !important;
            color: $color-primary-blur;
        }

        .ant-collapse-item {
            &:first-child {
                border-top: 1px solid #d9d9d9;
            }
        }

        ul {
            li {
                color: black;
                font-size: 14px;
            }
        }

        p {
            color: black;
            margin-bottom: 3px;
            font-size: 14px !important;
        }

        .ant-collapse-header {
            font-size: 18px;
            color: $color-primary-blur !important;
            font-weight: bold;
        }

        .ant-collapse {
            background-color: transparent;
        }

        &--collapse {
            background-color: $btn-color-primary;
            box-shadow: none;
            border: none;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            color: white;
            position: relative;

            &:before {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 16px;
                background: white;
                z-index: 999;
                height: 1px;
                bottom: 0;
                animation: collapse-close 0.3s;
                -ms-transform: rotate(90deg); /* IE 9 */
                transform: rotate(90deg);
            }

            &:after {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 16px;
                background: white;
                z-index: 999;
                height: 1px;
                bottom: 0;
            }

            &-rotate {
                background-color: $btn-color-primary;
                box-shadow: none;
                border: none;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                color: white;
                position: relative;

                &:before {
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 16px;
                    background: white;
                    z-index: 999;
                    height: 1px;
                    bottom: 0;
                }

                &:after {
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 16px;
                    background: white;
                    z-index: 999;
                    height: 1px;
                    bottom: 0;
                    animation: collapse-open 0.3s;
                }
            }
        }
    }

    &__info {
        &--item {
            -webkit-box-shadow: 1px 1px 9px -4px #000000;
            box-shadow: 1px 1px 9px -4px #000000;
            border-radius: 10px;
            //color: $color-primary-blur;
            background-color: #FFFFFF;

            &-description {
                font-size: 16px !important;
            }

            p {
                color: $color-primary-blur
            }

            &:hover {
                background-color: $color-primary-blur;

                p {
                    color: white;
                }

                .icon {
                    &--phone {
                        content: url("../layout/icon/hover/phone.svg");
                    }

                    &--send {
                        content: url("../layout/icon/hover/email.svg");
                    }

                    &--location {
                        content: url("../layout/icon/hover/location.svg");
                    }
                }

                p {
                    color: white
                }
            }
        }

        &--open {
            border-left: 9px solid $color-foreign;
            padding-left: 10px;
        }
    }

    &__slide {
        -webkit-box-shadow: 1px 1px 9px -4px #000000;
        box-shadow: 1px 1px 9px -4px #000000;
        border-radius: 10px;
        overflow: hidden;
    }
}
